















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class AppArea extends Vue {
  @Prop() readonly bg!: string;
}
